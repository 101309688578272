import React from 'react';
import styles from './Roadmap.module.css';
import { Box, Flex, Text } from '@chakra-ui/react';

const Roadmap = () => {
    return (
        <Box id='roadmap' display='inline-block' w='100%' bg='#0D3C5F'>
            <Flex
                mt='11rem'
                flexDir='column'
                fontFamily='Permanent Marker'
                fontSize={{ base: '2.75rem', md: '3.75rem' }}
                color='#D0FF34'
            >
                <Text>
                    ROADMAP ACTIVATIONS
                </Text>
            </Flex>
            <Flex
                flexDir='column'
                fontFamily='Open Sans'
                fontSize={{ base: '1rem', md: '1.25rem' }}
                color='#FFFFFF'
                w={{ base: '80%', md: '100%' }}
                m='auto'
                mt='2.5rem'
            >
                <Text>
                    Dappie Gang is here to stay.
                </Text>
                <Text>
                    Once we reach target sell through percentage, we will begin to
                </Text>
                <Text>
                    work on realizing the stated goal.
                </Text>
            </Flex>
            <Flex
                m='auto'
                mb='7rem'
                w={{ base: '90%', md: '60%' }}
                align='center'
                justify='center'
                flexDir='column'
                color='white'
            >
                <Flex w='100%' h='5rem' align='center' justify='center' position='relative'>
                    <Box className={styles.firstDot} />
                </Flex>
                <Flex className={styles.mission} >
                    <Box className={styles.dot} />
                    <Flex color='#FFFFFF' className={styles.content}>
                        <Text>20%</Text>
                        <Text>
                            Reveal the utility and perks of Genesis Dappies in Dappio Wonderland.
                        </Text>
                    </Flex>
                </Flex>
                <Flex className={styles.mission} >
                    <Box className={styles.dot} />
                    <Flex className={styles.content}>
                        <Text>40%</Text>
                        <Text>
                            Dappie Gang receives an Dappio Anthem to party in the Wonderland.
                        </Text>
                    </Flex>
                </Flex>
                <Flex className={styles.mission} >
                    <Box className={styles.dot} />
                    <Flex className={styles.content}>
                        <Text>60%</Text>
                        <Text>
                            Dappie Gang Wonderland activation with community activities and prizes announcement.
                        </Text>
                    </Flex>
                </Flex>
                <Flex className={styles.mission} >
                    <Box className={styles.dot} />
                    <Flex className={styles.content}>
                        <Text>80%</Text>
                        <Text>
                            Member-Exclusive Dappie Gang Merch Shop gets unlocked, featuring Limited Edition tees, hoodies and other stylish swags.
                        </Text>
                    </Flex>
                </Flex>
                <Flex className={styles.mission} >
                    <Box className={styles.dot} />
                    <Flex className={styles.content}>
                        <Text>90%</Text>
                        <Text>
                        5 DappieGang NFT will be airdropped to random DappieGang Holders.
                        </Text>
                    </Flex>
                </Flex>
                <Flex className={styles.mission} >
                    <Box className={`${styles.last} ${styles.dot}`} />
                    <Flex className={styles.content}>
                        <Text>100%</Text>
                        <Text>
                            It’s game time! We will build the game for Dappies.
                        </Text>
                    </Flex>
                </Flex>
            </Flex>
        </Box>
    )
};

export default Roadmap;