import React from 'react';
import { Flex, Image, Box, Link } from '@chakra-ui/react';
import AnchorLink from 'react-anchor-link-smooth-scroll'
import styles from './Header.module.css';
import HeaderBackground from '../../assets/image/header.png';
import Hamburger from '../../assets/icon/hamburger.png';
import Gang from '../../assets/icon/gang.png';
import MintG from '../../assets/image/Header/mintg.png';
import MintW from '../../assets/image/Header/mintw.png';
import AboutG from '../../assets/image/Header/aboutg.png';
import AboutW from '../../assets/image/Header/aboutw.png';
import UtilitiesG from '../../assets/image/Header/utilitiesg.png';
import UtilitiesW from '../../assets/image/Header/utilitiesw.png';
import RoadmapG from '../../assets/image/Header/roadmapg.png';
import RoadmapW from '../../assets/image/Header/roadmapw.png';
import TeamG from '../../assets/image/Header/teamg.png';
import TeamW from '../../assets/image/Header/teamw.png';
import Telegram from '../../assets/icon/telegramlight.svg';
import Twitter from '../../assets/icon/twitterlight.svg';
import Discord from '../../assets/icon/discordlight.svg';

const Header = props => {
    const { setMenu } = props;

    return (
        <>
            <Flex
                display={{ base: 'none', md: 'flex' }}
                justify='space-between'
                align='flex-start'
                bgPosition='center'
                bgRepeat='no-repeat'
                bgSize='cover'
                zIndex='9'
                position='fixed'
                w='100%'
            >
                <Flex
                    position='absolute'
                    left='4vw'
                    top='1vw'
                    align='flex-start'
                    justify='center'
                    className={styles.left}
                >
                    <Image
                        h="9vh"
                        src={Gang}

                    />
                    <Flex
                        align='center'
                        justify='center'
                        className={styles.anchors}
                    >
                        <AnchorLink href='#mint'>
                            <Box w='4.5vw' className={styles.anchor}>
                                <Image src={MintW} />
                                <Image src={MintG} />
                            </Box>
                        </AnchorLink>
                        <AnchorLink offset='100' href='#about'>
                            <Box w='5vw' className={styles.anchor}>
                                <Image src={AboutW} />
                                <Image src={AboutG} />
                            </Box>
                        </AnchorLink>
                        <AnchorLink offset='100' href='#utilities'>
                            <Box w='6vw' className={styles.anchor}>
                                <Image src={UtilitiesW} />
                                <Image src={UtilitiesG} />
                            </Box>
                        </AnchorLink>
                        <AnchorLink href='#roadmap'>
                            <Box w='6vw' className={styles.anchor}>
                                <Image src={RoadmapW} />
                                <Image src={RoadmapG} />
                            </Box>
                        </AnchorLink>
                        <AnchorLink href='#team'>
                            <Box w='5vw' className={styles.anchor}>
                                <Image src={TeamW} />
                                <Image src={TeamG} />
                            </Box>
                        </AnchorLink>
                    </Flex>
                </Flex>
                <Flex
                    className={styles.socials}
                    position='absolute'
                    right='4vw'
                    top='1.4vw'
                >
                    <Link
                        target="_blank"
                        outline='0'
                        _focus={{
                            boxShadow: 'none'
                        }}
                        href="https://twitter.com/DappieGangNFT"
                    >
                        <Image src={Twitter} />
                    </Link>
                    <Link
                        target="_blank"
                        outline='0'
                        _focus={{
                            boxShadow: 'none'
                        }}
                        href="https://discord.gg/aN4aevH2VP"
                    >
                        <Image src={Discord} />
                    </Link>
                    <Link
                        target="_blank"
                        outline='0'
                        _focus={{
                            boxShadow: 'none'
                        }}
                        href="https://t.me/Dappio_wonderland"
                    >
                        <Image src={Telegram} />
                    </Link>
                </Flex>
                <Image zIndex='-1' w='100%' position='absolute' src={HeaderBackground} />
            </Flex>
            <Flex
                display={{ base: 'flex', md: 'none' }}
                align='center'
                justify='space-between'
                zIndex='9'
                position='fixed'
                w='100%'
                h='10vh'
                bg='#001E34'
            >
                <Image m='1rem' h='3.56rem' src={Gang} />
                <Flex justify='center' align='center' className={styles.mobile_socials}>
                    <Link
                        target="_blank"
                        outline='0'
                        _focus={{
                            boxShadow: 'none'
                        }}
                        href="https://twitter.com/Dappio_"
                    >
                        <Image src={Twitter} />
                    </Link>
                    <Link
                        target="_blank"
                        outline='0'
                        _focus={{
                            boxShadow: 'none'
                        }}
                        href="https://discord.gg/aN4aevH2VP"
                    >
                        <Image src={Discord} />
                    </Link>
                    <Link
                        target="_blank"
                        outline='0'
                        _focus={{
                            boxShadow: 'none'
                        }}
                        href="https://t.me/Dappio_wonderland"
                    >
                        <Image src={Telegram} />
                    </Link>
                    <Image width='3rem' onClick={() => setMenu(true)} src={Hamburger} />
                </Flex>
            </Flex>
        </>
    );
};

export default Header;