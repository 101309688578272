import React from "react";
import { Box, Text, Flex, Image } from "@chakra-ui/react";
import styles from './Trait.module.css';
import Game from '../../assets/image/Trait/game.png';
import Character from '../../assets/image/Trait/character.png';
import Party from '../../assets/image/Trait/party.png';
import Discount from '../../assets/image/Trait/discount.png';
import Reward from '../../assets/image/Trait/reward.png';
import Airdrop from '../../assets/image/Trait/airdrop.png';


const Trait = () => {
    return (
        <Box id='utilities' w='100%' display='inline-block' bg='#001E34'>
            <Flex
                color='#D0FF34'
                fontSize={{ base: '2.75rem', md: '3.75rem' }}
                fontFamily='Permanent Marker'
                flexDirection='column'
                justify='center'
                align='center'
                mt='8.8rem'
                mb='5.3rem'
            >
                <Text>UTILITIES & PERKS TO</Text>
                <Text>DAPPIO WONDERLAND</Text>
            </Flex>
            <Flex w={{ base: '90%', md: '80%' }} className={styles.Traits}>
                <Flex flexDir='column'>
                    <Text
                        color='#D0FF34'
                        fontSize='1.875rem'
                        fontFamily='Permanent Marker'
                        casing='uppercase'
                    >
                        All dappies will have the special power
                    </Text>
                    <Text
                        color='#D0FF34'
                        fontSize='1.875rem'
                        fontFamily='Permanent Marker'
                        casing='uppercase'
                    >
                        and benefits
                    </Text>
                </Flex>
                <Flex  w='100%'  justify='center'  flexDir={{ base: 'column', md: 'row' }}>
                    <Flex className={styles.Trait} align='center' flexDir='column'>
                        <Image mt='1rem' w='7.5rem' src={Game} />
                        <Text
                            m='1rem'
                            color='white'
                            fontFamily='Open Sans'
                        >
                            Governor decide the future GameFi roadmap
                        </Text>
                    </Flex>
                    <Flex className={styles.Trait} align='center' flexDir='column'>
                        <Image mt='1rem' w='7.5rem' src={Character} />
                        <Text
                            m='1rem'
                            color='white'
                            fontFamily='Open Sans'
                        >
                            Character decide which game to have character proposal
                        </Text>
                    </Flex>
                    <Flex className={styles.Trait} align='center' flexDir='column'>
                        <Image mt='1rem' w='7.5rem' src={Party} />
                        <Text
                            m='1rem'
                            color='white'
                            fontFamily='Open Sans'
                        >
                            Party: participate in special campaign (Early Access)
                        </Text>
                    </Flex>
                </Flex>
            </Flex>
            <Flex  w={{ base: '90%', md: '80%' }} className={styles.Traits}>
                <Flex flexDir='column'>
                    <Text
                        color='#D0FF34'
                        fontSize='1.875rem'
                        fontFamily='Permanent Marker'
                        casing='uppercase'
                    >
                        Genesis Dappies are the ones with special traits that

                    </Text>
                    <Text
                        color='#D0FF34'
                        fontSize='1.875rem'
                        fontFamily='Permanent Marker'
                        casing='uppercase'
                    >
                        give Dappie holders perks in DAPPIO Wonderland
                    </Text>
                </Flex>
                <Flex w='100%' justify='center' flexDir={{ base: 'column', md: 'row' }}>
                    <Flex className={styles.Trait} align='center' flexDir='column'>
                        <Image mt='1rem' w='7.5rem' src={Discount} />
                        <Text
                            m='1rem'
                            color='white'
                            fontFamily='Open Sans'
                        >
                            Fee Discount
                        </Text>
                    </Flex>
                    <Flex className={styles.Trait} align='center' flexDir='column'>
                        <Image mt='1rem' w='7.5rem' src={Reward} />
                        <Text
                            m='1rem'
                            color='white'
                            fontFamily='Open Sans'
                        >
                            Reward Multiplier
                        </Text>
                    </Flex>
                    <Flex className={styles.Trait} align='center' flexDir='column'>
                        <Image mt='1rem' w='7.5rem' src={Airdrop} />
                        <Text
                            m='1rem'
                            color='white'
                            fontFamily='Open Sans'
                        >
                            Receive token airdrops
                        </Text>
                    </Flex>
                </Flex>
            </Flex>
        </Box>
    );
};

export default Trait;