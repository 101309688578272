import React from "react";
import { Box, Flex, Image, VStack } from "@chakra-ui/react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import './Rarity.css';
import Indicator from "./Indicator";
import Pink from '../../assets/image/Rarity/pink.jpg';
import Alien from '../../assets/image/Rarity/alien.jpeg';
import Sick from '../../assets/image/Rarity/sick.jpeg'

const Rarity = () => {
    return (
        <Box bg='#0D3C5F'>
            <Flex display={{ base: 'none', md: 'flex' }} fontSize='1.25rem' color='#FFFFFF' w='100%' overflow='hidden'>
                <VStack w='33.3%'>
                    <Image w='100%' src={Pink} />
                </VStack>
                <VStack w='33.3%'>
                    <Image w='100%' src={Sick} />
                </VStack>
                <VStack w='33.3%'>
                    <Image w='100%' src={Alien} />
                </VStack>
            </Flex>
            <Box 
                display={{ base: 'flex', md: 'none' }}
                fontSize='1.25rem' 
                color='#FFFFFF'
            >
                <Carousel
                    className="rarity"
                    showArrows={false}
                    showThumbs={false}
                    showStatus={false}
                    renderIndicator={(onClickHandler, isSelected, index, label) => {
                        return (
                            <Indicator
                                key={index}
                                clickHandler={onClickHandler}
                                isSelected={isSelected}
                            />
                        );
                    }}>
                    <VStack bg='#0D3C5F' w='100%'>
                        <Image w='100%' src={Pink} />
                    </VStack>
                    <VStack bg='#0D3C5F' w='100%'>
                        <Image w='100%' src={Sick} />
                    </VStack>
                    <VStack bg='#0D3C5F' w='100%'>
                        <Image w='100%' src={Alien} />
                    </VStack>
                </Carousel>
            </Box>
        </Box>
    );
};

export default Rarity;