import React from "react";
import { Box, Flex, Image, Text } from "@chakra-ui/react";
import Crown from '../../assets/image/Discription/crown.png';
import Chain from '../../assets/image/Discription/chain.png';
import Skate from '../../assets/image/Discription/skate.png';
import LSD from '../../assets/image/Discription/lsd.png';

const Description = () => {
    return (
        <Box  id='about' bg='#001E34' overflow='hidden'>
            <Flex
                mt='8rem'
                flexDir='column'
                fontSize='3.75rem'
                fontFamily='Permanent Marker'
                lineHeight='3.5rem'
                color='#D0FF34'
            >
                <Image
                    w='15rem'
                    m='auto'
                    display={{ base: 'flex', md: 'none' }}
                    src={LSD}
                />
                <Text>
                    WELCOME
                </Text>
                <Text>
                    TO THE GANG
                </Text>
            </Flex>
            <Flex
                justify='space-around'
                align='center'
                m='4rem 0'
            >
                <Box
                    flexBasis='25%'
                    position='relative'
                    display={{ base: 'none', md: 'flex' }}
                >
                    <Image
                        w='13rem'
                        top='-12rem'
                        left='6rem'
                        position='absolute'
                        src={LSD}
                    />
                </Box>
                <Text
                    fontSize='1.25rem'
                    fontFamily='Open Sans'
                    color='#FFFFFF'
                    flexBasis={{ base: '85%', md: '50%' }}
                >
                    Each Dappie is unique and programmatically generated from over 286 possible traits,
                    including skin, expression, tattoo, headwear,
                    clothing, and more. All Dappies are dope,
                    but some are rarer than others.
                </Text>
                <Box
                    flexBasis='25%'
                    position='relative'
                    display={{ base: 'none', md: 'flex' }}
                >
                    <Image
                        w='15rem'
                        top='-9rem'
                        left='2rem'
                        position='absolute'
                        src={Chain}
                    />
                </Box>
            </Flex>
            <Flex
                justify='space-around'
                align='center'
                m='4rem 0'
                mb='12rem'
            >
                <Box
                    flexBasis='25%'
                    position='relative'
                    display={{ base: 'none', md: 'flex' }}
                >
                    <Image
                        w='15rem'
                        top='-9rem'
                        left='5rem'
                        position='absolute'
                        src={Crown}
                    />
                </Box>
                <Text
                    fontSize='1.25rem'
                    fontFamily='Open Sans'
                    color='#FFFFFF'
                    flexBasis={{ base: '85%', md: '50%' }}
                >
                    When you buy a Dappie,
                    you’re not simply buying an avatar or a provably-rare piece of art.
                    You are gaining membership access to a gang whose benefits and offerings will increase over time.
                    Your Dappie can serve as your digital identity,
                    and open digital doors for you in the Solana Wonderland.
                </Text>
                <Box
                    flexBasis='25%'
                    position='relative'
                    display={{ base: 'none', md: 'flex' }}
                >
                    <Image
                        w='22rem'
                        top='-15rem'
                        left='1rem'
                        position='absolute'
                        src={Skate}
                    />
                </Box>
            </Flex>
        </Box>
    );
};

export default Description;