import React from "react";
import {
    Box,
    Image,
    Text,
    Flex,
} from "@chakra-ui/react";
/*
import { Carousel } from 'react-responsive-carousel';
import A from '../../assets/image/Distribution/1.jpg';
import B from '../../assets/image/Distribution/2.jpg';
import C from '../../assets/image/Distribution/3.jpg';
import D from '../../assets/image/Distribution/4.jpg';
import E from '../../assets/image/Distribution/5.webp';
import F from '../../assets/image/Distribution/6.jpg';
*/
import GIF from '../../assets/image/Distribution/gif.gif';
//import Indicator from "../Gallery/Indicator";

const Distribution = props => {
    return (
        <Box id='mint' w='100%' display='inline-block' bg='#0D3C5F'>
            <Flex
                m={['0rem auto 8rem auto', '8rem auto']}
                flexWrap='wrap'
                justify='center'
            >
                {/*
                <Box
                    w={['100%', '20%']}
                    mr={['0', '2rem']}
                    flexBasis={['100%', '35%']}
                >
                    <Carousel
                        autoPlay={true}
                        interval={1000}
                        infiniteLoop={true}
                        showArrows={false}
                        showThumbs={false}
                        renderIndicator={(onClickHandler, isSelected, index, label) => {
                            return (
                                <Indicator
                                    key={index}
                                    clickHandler={onClickHandler}
                                    isSelected={isSelected}
                                />
                            );
                        }}
                    >
                        <Image
                            src={A}
                        />
                        <Image
                            src={B}
                        />
                        <Image
                            src={C}
                        />
                         <Image
                            src={D}
                        />
                        <Image
                            src={E}
                        />
                        <Image
                            src={F}
                        />

                    </Carousel>
                </Box>
                */}
                <Image
                    w={['100%', '20%']}
                    mr={['0', '2rem']}
                    flexBasis={['100%', '35%']}
                    src={GIF}
                />
                <Box
                    flexBasis={['100%', '35%']}
                    m={['3rem', '1rem 0 0 2rem']}
                >
                    <Flex
                        flexDir='column'
                        align={['center', 'flex-start']}
                        fontFamily='Permanent Marker'
                        color='#D0FF34'
                        fontSize='3rem'
                    >
                        <Text >FAIR</Text>
                        <Text lineHeight='1.5rem'>DISTRIBUTION</Text>
                    </Flex>
                    <Flex
                        flexDir='column'
                        mt='2rem'
                        align={['center', 'flex-start']}
                        textAlign={['center', 'left']}
                        fontFamily='Open Sans'
                        color='#FFFFFF'
                    >
                        <Text

                        >
                            There are no price tiers and bonding curves here.
                        </Text>
                    </Flex>
                    <a href="https://www.magiceden.io/marketplace/dappie_gang">
                        <Text
                            fontFamily='Permanent Marker'
                            fontSize={['3rem', '1.875rem']}
                            m={['2.8rem auto', '2.8rem 0']}
                            p='0.5rem 1.9rem'
                            w={['90%', 'fit-content']}
                            bg='#D0FF34'
                            borderRadius='10px'
                            color='#0D3C5F'
                            cursor='pointer'
                            _hover={{
                                border: '2px solid #D0FF34',
                                background: 'none',
                                color: '#D0FF34'
                            }}
                        >
                            TRADE HERE
                        </Text>
                    </a>
                </Box>
            </Flex>
        </Box>
    )
};

export default Distribution;