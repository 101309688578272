import React from "react";
import { Box, Flex, Text, Image, Link } from "@chakra-ui/react";
import styles from './Team.module.css';
import BassJackers from '../../assets/image/Team/bassjackers.png';
import Genopets from '../../assets/image/Team/genopets.png';
import Dappio from '../../assets/image/Team/dappio.png';
import Twitter from '../../assets/icon/twitterlight.svg';
import Background from '../../assets/image/Team/background.png';

const Team = () => {
    return (
        <Box id='team' display='inline-block' w='100%' bg='#001E34'>
            <Text
                fontFamily='Permanent Marker'
                fontSize={{ base: '3rem', md: '3.75rem' }}
                color='#D0FF34'
                mt='7.5rem'
                mb='3rem'
            >
                Dappie Gang’s Partner
            </Text>
            <Flex m='auto' w='60%' justify='center' flexWrap='wrap'>
                <Flex className={styles.member}>
                    <Image src={Dappio} />
                    <Text>Ecosystem Partner</Text>
                    <Flex className={styles.social}>
                        <Link
                            outline='0'
                            _focus={{
                                boxShadow: 'none'
                            }}
                            target='_blank'
                            href='https://twitter.com/Dappio_'
                        >
                            <Image src={Twitter} />
                        </Link>
                    </Flex>
                </Flex>
                <Flex className={styles.member}>
                    <Image src={Genopets} />
                    <Text>Ecosystem Partner</Text>
                    <Flex className={styles.social}>
                        <Link
                            outline='0'
                            _focus={{
                                boxShadow: 'none'
                            }}
                            target='_blank'
                            href='https://twitter.com/genopets'
                        >
                            <Image src={Twitter} />
                        </Link>
                    </Flex>
                </Flex>
                <Flex className={`${styles.member} ${styles.bassjackers}`}>
                    <Image w={{base: '80%', md: '80%'}} src={BassJackers} />
                    <Text>Music Partner</Text>
                    <Flex className={styles.social}>
                        <Link
                            outline='0'
                            _focus={{
                                boxShadow: 'none'
                            }}
                            target='_blank'
                            href='https://twitter.com/bassjackers'

                        >
                            <Image src={Twitter} />
                        </Link>
                    </Flex>
                </Flex>
            </Flex>
            <Image w='100%' src={Background} />
        </Box>
    );
};

export default Team;