import React from "react";
import { Box, Flex, Image, Link } from "@chakra-ui/react";
import AnchorLink from 'react-anchor-link-smooth-scroll';
import styles from './Menu.module.css';
import Gang from '../../assets/image/Header/gangdark.png';
import Cross from '../../assets/image/Header/cross.png';
import MintG from '../../assets/image/Header/mintg.png';
import MintW from '../../assets/image/Header/mintw.png';
import AboutG from '../../assets/image/Header/aboutg.png';
import AboutW from '../../assets/image/Header/aboutw.png';
import UtilitiesG from '../../assets/image/Header/utilitiesg.png';
import UtilitiesW from '../../assets/image/Header/utilitiesw.png';
import RoadmapG from '../../assets/image/Header/roadmapg.png';
import RoadmapW from '../../assets/image/Header/roadmapw.png';
import TeamG from '../../assets/image/Header/teamg.png';
import TeamW from '../../assets/image/Header/teamw.png';
import Telegram from '../../assets/icon/telegramlight.svg';
import Twitter from '../../assets/icon/twitterlight.svg';
import Discord from '../../assets/icon/discordlight.svg';

const Menu = props => {
    const { menu, setMenu } = props;

    return (
        <Box
            position='fixed'
            zIndex='99'
            bg='#001E34'
            w='100vw'
            h='100vh'
            display={{ base: menu ? 'flex' : 'none', md: 'none' }}
        >
            <Flex
                position='absolute'
                align='center'
                justify='space-between'
                top='0'
                w='100%'
                h='10vh'
            >
                <Image m='1rem' h='3.56rem' src={Gang} />
                <Image onClick={() => setMenu(false)} m='1rem' w='2rem' src={Cross} />
            </Flex>
            <Flex flexDir='column' justify='space-between' w='100%' h='90vh' m='auto'>
                <Flex
                    w='100%'
                    h='70%'
                    mt='15%'
                    align='center'
                    justify='space-around'
                    className={styles.anchors}
                >
                    <AnchorLink onClick={() => setMenu(false)} href='#mint'>
                        <Box className={styles.anchor}>
                            <Image w='25%' src={MintW} />
                            <Image w='25%' src={MintG} />
                        </Box>
                    </AnchorLink>
                    <AnchorLink onClick={() => setMenu(false)} href='#about'>
                        <Box className={styles.anchor}>
                            <Image w='30%' src={AboutW} />
                            <Image w='30%' src={AboutG} />
                        </Box>
                    </AnchorLink>
                    <AnchorLink onClick={() => setMenu(false)} href='#utilities'>
                        <Box className={styles.anchor}>
                            <Image w='40%' src={UtilitiesW} />
                            <Image w='40%' src={UtilitiesG} />
                        </Box>
                    </AnchorLink>
                    <AnchorLink onClick={() => setMenu(false)} href='#roadmap'>
                        <Box className={styles.anchor}>
                            <Image w='40%' src={RoadmapW} />
                            <Image w='40%' src={RoadmapG} />
                        </Box>
                    </AnchorLink>
                    <AnchorLink onClick={() => setMenu(false)} href='#team'>
                        <Box className={styles.anchor}>
                            <Image w='30%' src={TeamW} />
                            <Image w='30%' src={TeamG} />
                        </Box>
                    </AnchorLink>
                </Flex>
                <Flex
                    align='center'
                    justify='space-between'
                    w='100%'
                >
                    <Flex m='auto' w='60%' justify='space-around' align='center' className={styles.mobile_socials}>
                        <Link
                            target="_blank"
                            outline='0'
                            _focus={{
                                boxShadow: 'none'
                            }}
                            href="https://twitter.com/DappieGangNFT"
                        >
                            <Image src={Twitter} />
                        </Link>
                        <Link
                            target="_blank"
                            outline='0'
                            _focus={{
                                boxShadow: 'none'
                            }}
                            href="https://discord.gg/aN4aevH2VP"
                        >
                            <Image src={Discord} />
                        </Link>
                        <Link
                            target="_blank"
                            outline='0'
                            _focus={{
                                boxShadow: 'none'
                            }}
                            href="https://t.me/Dappio_wonderland">
                            <Image src={Telegram} />
                        </Link>
                    </Flex>
                </Flex>
            </Flex>
        </Box>
    );
}

export default Menu;