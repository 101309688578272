import React from 'react';
import '@fontsource/permanent-marker';
import '@fontsource/open-sans';
import {
  ChakraProvider,
  Box,
  Grid,
} from '@chakra-ui/react';
import Fonts from './fonts';
import theme from './theme';
import { Home } from './pages';

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Fonts />
      <Box textAlign="center" fontSize="xl">
        <Grid minH="100vh">
          <Home />
        </Grid>
      </Box>
    </ChakraProvider>
  );
}

export default App;
