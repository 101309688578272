import React from "react";
import { Box } from "@chakra-ui/react";

const Indicator = ({
    clickHandler,
    isSelected,
}) => {
    return (
        <Box
            onClick={clickHandler}
            display='inline-block'
            h='0.75rem'
            w='0.75rem'
            bg={isSelected ? '#D0FF34':'#0D3C5F'}
            borderRadius='50%'
            m='0 1rem'
            bottom='3rem'
        />
    )
};

export default Indicator;