import {
    Box,
//    VStack,
    HStack,
    Image,
    Text,
////    Flex
} from '@chakra-ui/react';
import React from 'react';
//import styles from './About.module.css';
import DownArrow from '../../assets/icon/downArrow.png';
//import Dapp from '../../assets/image/About/dapp.png';
//import StreetArt from '../../assets/image/About/streetArt.png';
//import NFT from '../../assets/image/About/nft.png';

const About = () => {
    return (
        <Box bg="#001E34" display='inline-block'>
            <HStack display={{ base: 'none', md: 'flex' }} mt='1rem' align="center" justify="center" >
                <Image h='3.5rem' src={DownArrow} />
                <Text
                    fontFamily='Permanent Marker'
                    fontSize='3.75rem'
                    color="white"
                    p="0 1.875rem"
                >
                    DOWN THE RABBIT HOLE
                </Text>
                <Image h='3.5rem' src={DownArrow} />
            </HStack>
            <Box textAlign='center'>
                <Text
                    mt='8rem'
                    fontFamily='Permanent Marker'
                    fontSize='3.75rem'
                    color='#D0FF34'
                >
                    DAPPIE GANG NFT
                </Text>
            </Box>
            <Text
                fontFamily='Open Sans'
                fontSize='1.25rem'
                color='#FFFFFF'
                m='auto'
                mt='2.5rem'
                mb='10rem'
                w={['90%', '80%', '70%', '50%']}
            >
                Dappie Gang is a collection of 6,666 Rabbit NFTs — unique rabbit GameFi characters with DeFi utilities in Solana blockchain. Dappie Gang is your key to access members-only benefits, the first of which is access to DAPPIO, Solana Wonderland. Exclusive benefits and perks can be unlocked by the community through roadmap activation.            </Text>
            {/*
            <Flex
                className={styles.tanks}
                justify='center'
            >
                <VStack
                    className={styles.tank}
                >
                    <Image src={Dapp} />
                    <Text
                        color='#F749F7'
                    >
                        DAPP
                    </Text>
                </VStack>
                <VStack
                    className={styles.tank}
                >
                    <Image src={StreetArt} />
                    <Text
                        color='#2BBFFF'
                    >
                        STREET ART
                    </Text>
                </VStack>
                <VStack
                    className={styles.tank}
                >
                    <Image src={NFT} />
                    <Text
                        color='#06FBB2'
                    >
                        NFT
                    </Text>
                </VStack>
            </Flex>
            */}
        </Box>
    );
};

export default About;