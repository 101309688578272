import React, { useState } from 'react';
import {
    Box,
    Image,
} from '@chakra-ui/react';
import {
    Header,
    About,
    Distribution,
    Description,
    Util,
    Trait,
    Rarity,
    Roadmap,
    Team,
    Menu
} from '../../component';
import Banner from '../../assets/image/banner.jpg';
import Mint from '../../assets/image/mint.png';
import BannerM from '../../assets/image/bannerMobile.jpg';


const Home = props => {
    const [menu, setMenu] = useState(false);

    return (
        <Box id='page-wrap' >
            <Image
                //display={{ base: 'none', md: 'flex' }}
                display='none'
                src={Mint}
                cursor='pointer'
                position='fixed'
                w='8rem'
                zIndex='9'
                right='0'
                top='40%'
            />
            <Menu menu={menu} setMenu={setMenu} />
            <Header menu={menu} setMenu={setMenu} />
            <Image
                position='relative'
                zIndex='9'
                src={Banner}
                display={{ base: 'none', md: 'flex' }}
            />
            <Image
                position='relative'
                zIndex='9'
                src={BannerM}
                display={{ base: 'flex', md: 'none' }}
            />
            <About />
            <Distribution />
            <Description />
            <Util />
            <Trait />
            <Rarity />
            <Roadmap />
            <Team />
        </Box>
    );
};

export default Home;