import React from "react";
import {
    Box,
    Text,
    Flex,
    HStack,
    Image
} from "@chakra-ui/react";
import Coin from '../../assets/image/Util/coin.png';
import Libra from '../../assets/image/Util/libra.png';
import Suitcase from '../../assets/image/Util/box.png';
import Hat from '../../assets/image/Util/hat.png';
import Give from '../../assets/image/Util/give.png';
import HalfPink from '../../assets/image/Util/spink.png';
import HalfGrey from '../../assets/image/Util/sgrey.png';
import Grey from '../../assets/image/Util/grey.png';

const Util = () => {
    const contents = [
        {
            title: 'NFT Token',
            content: '6,666 Provably-rare Dappie tokens', 
            pic: Coin
        },
        { 
            title: 'Fair Launch',
            content: 'Fair Launch, fair distribution.', 
            pic: Libra
        },
        {
            title: 'Commerce',
            content: 'Ownership and commercial usage rights given to the holder over their NFT',
            pic: Suitcase
        },
        {
            title: 'Addition',
            content: 'With utilities and perk in DAPPIO Wonderland, such as fee discount, reward multiplier, airdrops, and governor…etc.',
            pic: Hat
        },
        {
            title: 'More reward',
            content: 'Gain additional benefits through roadmap activation',
            pic: Give
        }
    ];
    return (
        <Box position='relative' display='inline-block' bg='#0D3C5F'>
            <Flex
                w={{ base: '100%', md: '95%' }}
                m='auto'
                mt={{ base: '3rem', md: '8rem' }}
                mb={{ base: '35rem', md: '27rem' }}
                justify='center'
                flexWrap='wrap'
            >
                {
                    contents.map(
                        (content, index) => {
                            return (
                                <Box key={content.title} p='3rem' flexBasis={{ base: '100%', md: '30%' }}>
                                    <Flex
                                        w='100%'
                                        h='100%'
                                        m='auto'
                                        flexDir='column'
                                        align='center'
                                        padding='1rem 0.8rem'
                                        border='2px solid #D0FF34'
                                        borderRadius='20px'
                                        bg='#145483'
                                        fontFamily='Permanent Marker'
                                        fontSize={{ base: '4rem', md: '1.875rem' }}
                                        boxShadow='6px 6px 6px 6px rgba(0, 0, 0, .245)'
                                    >
                                        <HStack
                                            align='center'
                                            justify='center'
                                        >
                                            <Box
                                                position='relative'
                                                mr='0.5rem'
                                                h={{ base: '4rem', md: '2rem' }}
                                                w={{ base: '4rem', md: '2rem' }}
                                            >
                                                <Text
                                                    fontSize={{ base: '3rem', md: '1.875rem' }}
                                                    position='absolute'
                                                    top='-20%'
                                                    left='20%'
                                                    color='#0D3C5F'
                                                >
                                                    {index + 1}
                                                </Text>
                                                <Box
                                                    bg='#D0FF34'
                                                    borderRadius='50%'
                                                    h={{ base: '4rem', md: '2rem' }}
                                                    w={{ base: '4rem', md: '2rem' }}
                                                />
                                            </Box>
                                            <Text fontSize='1.8rem' color='#D0FF34'>
                                                {content.title}
                                            </Text>
                                        </HStack>
                                        <Image w={{ base: '18rem', md: '14rem' }} m='3rem 0' src={content.pic} />
                                        <Text
                                            m='3rem 0rem'
                                            fontFamily='Open Sans'
                                            fontSize={{ base: '1.8rem', md: '1.25rem' }}
                                            color='#D0FF34'
                                        >
                                            {content.content}
                                        </Text>
                                    </Flex>
                                </Box>
                            );
                        }
                    )
                }

            </Flex>
            <Image display={{ base: 'none', md: 'flex' }} w='22rem' position='absolute' left='0' bottom='0' src={HalfPink} />
            <Image display={{ base: 'none', md: 'flex' }} w='22rem' position='absolute' right='0' bottom='0' src={HalfGrey} />
            <Image display={{ base: 'flex', md: 'none' }} w='90%' position='absolute' right='0' bottom='0' src={Grey} />
        </Box>
    );
};

export default Util;